<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">Okay! Let's start</div>
                <div class="quote-title">
                    with your Mods
                    <div class="button btn btn-sm btn-secondary text-white btn-icon d-inline p-0 question-button align-top ms-1" type="button" (click)="showHelp()"><i-bs class="d-inline-block" name="question" width="1.7rem" height="1.7rem"></i-bs></div>
                </div>
            </div>
            <div class="d-md-block d-none">
                <div class="quote-title">
                    Okay! Let's start with your Mods
                    <div class="button btn btn-sm btn-secondary text-white btn-icon d-inline p-0 align-top ms-1" type="button" (click)="showHelp()"><i-bs class="d-inline-block" name="question" width="1.7rem" height="1.7rem"></i-bs></div>
                </div>
            </div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-5">
            <div class="quote-subtitle">Give us your best guess of the value of every modification to your rig.</div>
            <div class="quote-subtitle">Yes, you can include labor and installation costs!</div>
            <div class="quote-subtitle text-cyan-blue mt-2">Note: You will be required to upload photos of your rig after purchase.</div>
        </div>
        <img class="max-width-image mb-md-5 mb-3" width="500px" src="/assets/img/svg/overland-design/od_mods_full.svg" alt="Modifications svg" />
        <div class="row justify-content-center">
            <div class="col-lg-4 col-12"><ng-template [ngTemplateOutlet]="modsDetailsFormTemplate"></ng-template></div>
        </div>
        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="quoteFlowModsDetailsNextButton" type="button" (click)="goNext()" tabindex="0" [attr.disabled]="!modsValueControlValid ? true : null">
            <div class="d-flex align-items-center justify-content-center">Next</div>
            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
        </button>
        <div class="d-flex justify-content-start"><sbf-back-button data-cy="quoteFlowCoverageSelectBackButton" (click)="back.emit()"></sbf-back-button></div>
    </div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="modsDetailsFormTemplate"></ng-template></ng-container
><ng-template #modsDetailsFormTemplate
    ><form data-cy="modsDetailsForm" [formGroup]="modsDetailsForm">
        <div class="form-floating mb-3">
            <input class="form-control valid-override" id="modsValueInput" data-cy="modsValueInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="modsValueHelp" sbwAutoFocus [formOnly]="formOnly" formControlName="modsValue" [class.is-invalid]="modsValueControlInvalid" [class.is-valid]="modsValueControlValid" placeholder="modsValue" prefix=" $" mask="separator.0" thousandSeparator="," />
            <div class="invalid-feedback" *ngIf='modsValueControl.hasError("required")'>Mods Value required.</div>
            <div class="invalid-feedback" data-cy="invalidFeedbackMin" *ngIf='modsValueControl.hasError("min")'>Value must be {{valueLimits.mods.min | currency : 'USD' : 'symbol' : '1.0'}} or greater.</div>
            <div class="invalid-feedback" data-cy="invalidFeedbackMax" *ngIf='modsValueControl.hasError("max")'>Value must be {{valueLimits.mods.max | currency : 'USD' : 'symbol' : '1.0'}} or less.</div>
            <div class="valid-feedback" *ngIf="modsValueRounded">Mods Value rounded.</div>
            <label class="text-gray-600 small" for="modsValueInput">Total Value of your mods</label>
        </div>
    </form></ng-template
>
