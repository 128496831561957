<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">Tell us more about</div>
                <div class="quote-title">your trailer</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">Tell us more about your trailer</div></div>
        </div>
        <img class="max-width-image mb-md-5 mb-3" width="425px" style="height: 200px" src="/assets/img/svg/overland-design/od_trailer_full.svg" alt="Trailer svg" />
        <div class="row justify-content-center">
            <div class="col-lg-8 col-12">
                <div class="card">
                    <div class="card-body">
                        <ng-template [ngTemplateOutlet]="trailerDetailsFormTemplate"></ng-template
                        ><button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-5 mt-md-5 mt-3" data-cy="quoteFlowTrailerDetailsNextButton" type="button" (click)="goNext()" tabindex="0" [attr.disabled]="!allValid ? true : null">
                            <div class="d-flex align-items-center justify-content-center">Next</div>
                            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
                        </button>
                        <div class="d-flex justify-content-start"><sbf-back-button data-cy="quoteFlowTrailerDetailsBackButton" (click)="back.emit()"></sbf-back-button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="trailerDetailsFormTemplate"></ng-template></ng-container
><ng-template #trailerDetailsFormTemplate
    ><form data-cy="trailerDetailsForm" [formGroup]="trailerDetailsForm">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="mb-3"><sbf-input-select data-cy="unitClassSelect" idAppend="unitClass" formControlName="unitClass" defaultText="Unit Class*" [optionsRecordsArray]="[eRocUnitClassTrailerText]"></sbf-input-select></div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-4 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control valid-override" id="unitValueInput" data-cy="unitValueInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="unitValueHelp" sbwAutoFocus [formOnly]="formOnly" formControlName="unitValue" [class.is-invalid]="unitValueControlInvalid" [class.is-valid]="unitValueControlValid" placeholder="unitValue" prefix=" $" mask="separator.0" thousandSeparator="," />
                    <div class="invalid-feedback" *ngIf='unitValueControl.hasError("required")'>Trailer Value required.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackValueMin" *ngIf='unitValueControl.hasError("min")'>Value must be {{valueLimits.trailer.min | currency : 'USD' : 'symbol' : '1.0'}} or greater.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackValueMax" *ngIf='unitValueControl.hasError("max")'>Value must be {{valueLimits.trailer.max | currency : 'USD' : 'symbol' : '1.0'}} or less.</div>
                    <div class="valid-feedback" *ngIf="trailerValueRounded">Trailer Value rounded.</div>
                    <label class="text-gray-600 small" for="unitValueInput">Trailer Value</label>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="unitLengthInput" data-cy="unitLengthInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="unitLengthHelp" formControlName="unitLength" [class.is-invalid]="unitLengthControlInvalid" placeholder="unitLength" mask="999" />
                    <div class="invalid-feedback" *ngIf='unitLengthControl.hasError("required")'>Trailer Length required.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackLengthMax" *ngIf='unitLengthControl.hasError("max")'>Trailer Length must be 100 or less.</div>
                    <label class="text-gray-600 small" for="unitLengthInput">Trailer Length (feet)</label>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="unitAgeInput" data-cy="unitAgeInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="unitAgeHelp" formControlName="unitAge" [class.is-invalid]="unitAgeControlInvalid" placeholder="unitAge" mask="99" />
                    <div class="invalid-feedback" *ngIf='unitAgeControl.hasError("required")'>Trailer Age required.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackAgeMax" *ngIf='unitAgeControl.hasError("max")'>Trailer Age must be 20 years or less</div>
                    <label class="text-gray-600 small" for="unitAgeInput">Trailer Age</label>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="unitOwnedTenureInput" data-cy="unitOwnedTenureInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="unitOwnedTenureHelp" formControlName="unitOwnedTenure" [class.is-invalid]="unitOwnedTenureControlInvalid" placeholder="unitOwnedTenure" mask="99" />
                    <div class="invalid-feedback" *ngIf='unitOwnedTenureControl.hasError("required")'>Years Owned required.</div>
                    <div class="invalid-feedback" data-cy="invalidFeedbackOwnedTenureMax" *ngIf='unitOwnedTenureControl.hasError("max")'>Years Owned must be 20 years or less.</div>
                    <label class="text-gray-600 small" for="unitOwnedTenureInput">Years Owned</label>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="unitMakeInput" data-cy="unitMakeInput" autocomplete="off" type="text" aria-describedby="unitMakeHelp" formControlName="unitMake" [class.is-invalid]="unitMakeControlInvalid" placeholder="unitMake" />
                    <div class="invalid-feedback" *ngIf='unitMakeControl.hasError("required")'>Trailer Make required.</div>
                    <label class="text-gray-600 small" for="unitMakeInput">Make</label>
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-12">
                <div class="form-floating mb-3">
                    <input class="form-control" id="unitModelInput" data-cy="unitModelInput" autocomplete="off" type="text" aria-describedby="unitModelHelp" formControlName="unitModel" [class.is-invalid]="unitModelControlInvalid" placeholder="unitModel" />
                    <div class="invalid-feedback" *ngIf='unitModelControl.hasError("required")'>Trailer Model required.</div>
                    <label class="text-gray-600 small" for="unitModelInput">Model</label>
                </div>
            </div>
        </div>
    </form></ng-template
>
