import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: '3c6f57b412a8b94f5e4648e7b2bfa943f23dd542',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.1.3',
    dateFormatted: '2024-10-29--22-51',
    dateISOString: '2024-10-29T22:51:32.758Z',
};
